<template>
  <div v-if="order">
    <div v-if="order.fulfillmentStatus !== 'fulfilled'" class="elevation-form pa-3">
      <div class="d-flex align-center mb-6">
        <v-avatar size="32" class="mr-2">
          <img :src="imageFake.unfulfilled" alt="" />
        </v-avatar>
        <h4>Unfulfilled ({{ itemsUnfulfilled.length }})</h4>
      </div>
      <v-divider class="my-5"></v-divider>
      <v-simple-table>
        <tbody>
          <template v-for="item in itemsUnfulfilled">
            <tr v-if="item.sku !== 'tip'" :key="item.item_id">
              <td>
                <v-badge bordered color="#dddddd" :content="item.qty" overlap
                  ><div class="image-item">
                    <img v-if="!item.image" style="width: 50px" :src="'@/assets/images/no-image.png'" alt="" />
                    <img style="width: 100%" v-else :src="imageHelpers.url(item.image)" />
                  </div>
                </v-badge>
              </td>
              <td class="py-3">
                <router-link :to="{ name: 'website.products.update', params: { id: item.product_id } }">
                  {{ item._title }}
                </router-link>
                <div>{{ item._variant_variantTitle }}</div>
                <div v-for="(personalize, index) in item.personalizeText" :key="index">
                  {{ personalize.name ? personalize.name : 'Name' }}: {{ personalize.text }}
                </div>
                <div>SKU:{{ item._productSku ? item._productSku : '' }}</div>
                <div
                  v-if="
                    item._vendor === 'personalbridge' &&
                      item.attributes &&
                      item.attributes.find(i => i.key === 'custom_info')
                  "
                >
                  <div
                    v-for="(info, index) in item.attributes.find(i => i.key === 'custom_info').value[0].custom"
                    :key="index + 'info'"
                  >
                    <div v-if="info.label && info.label === undefined">{{ info.title }}: {{ info.value }}</div>
                  </div>
                </div>
                <div class="preview" v-if="item._vendor === 'personalbridge'">
                  <v-btn depressed class="mr-2" color="primary" small @click="openPreview(item)">
                    Mockup Custom
                  </v-btn>
                  <v-btn
                    target="_blank"
                    :href="item.attributes.find(i => i.key === 'print_files').value[0].url"
                    v-if="
                      item &&
                        item.attributes &&
                        item.attributes.length > 0 &&
                        item.attributes.find(i => i.key === 'print_files') &&
                        item.attributes.find(i => i.key === 'print_files').value[0] &&
                        item.attributes.find(i => i.key === 'print_files').value[0].url &&
                        item._vendor === 'personalbridge'
                    "
                    color="primary"
                    small
                  >
                    Design Custom
                  </v-btn>
                </div>
              </td>
              <td style="width: 100px">{{ currency.priceFormat(item.price) }} x {{ item.qty }}</td>
              <td>{{ currency.priceFormat(parseFloat(item.price * item.qty).toFixed(2)) }}</td>
            </tr>
            <tr v-if="item.sku === 'tip'" :key="item.item_id">
              <td>
                <v-badge bordered color="#dddddd" :content="item.qty" overlap
                  ><div class="image-item">
                    <img v-if="!item.image" style="width: 50px" :src="'@/assets/images/no-image.png'" alt="" />
                    <img style="width: 100%" v-else :src="imageHelpers.url(item.image)" />
                  </div>
                </v-badge>
              </td>
              <td class="py-3">
                <span>
                  {{ item._title }}
                </span>
                <div>{{ item._variant_variantTitle }}</div>
                <div v-for="(personalize, index) in item.personalizeText" :key="index">
                  {{ personalize.name ? personalize.name : 'Name' }}: {{ personalize.text }}
                </div>
                <div>SKU:{{ item._productSku ? item._productSku : '' }}</div>
              </td>
              <td style="width: 100px">{{ currency.priceFormat(item.price) }} x {{ item.qty }}</td>
              <td>{{ currency.priceFormat(parseFloat(item.price * item.qty).toFixed(2)) }}</td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
      <v-divider class="my-5"></v-divider>
      <div class="d-flex align-center mb-6">
        <v-spacer />
        <!-- <v-btn elevation="" color="" class="mr-2">Fulfill with <i class="fas fa-caret-down ml-2"></i></v-btn> -->
        <v-btn elevation="" color="primary" @click="$router.push({ name: 'website.orders.fulfillment' })">
          Mark as fulfilled
        </v-btn>
      </div>
    </div>
    <div v-for="fulfill in fulFillmentList" :key="fulfill._id" class="elevation-form pa-3 mt-3">
      <div class="d-flex align-center mb-6 mr-2">
        <v-avatar size="32" class="mr-2">
          <img :src="'@/assets/images/checked.png'" alt="" />
        </v-avatar>
        <h4>Fulfilled by Dcomcy ({{ fulfill.sum }})</h4>
      </div>
      <v-divider class="my-5"></v-divider>
      <v-simple-table>
        <tbody>
          <tr v-for="item in fulfill.itemFulfill" :key="item.item_id">
            <td>
              <!-- <v-badge bordered color="#dddddd" :content="item.qty" overlap
                ><div class="image-item">
                  <img v-if="!item.image" style="width:50px" :src="'@/assets/images/no-image.png'" alt="" />
                  <img style="width: 100%" v-else :src="imageHelpers.url(item.image)" />
                </div>
              </v-badge> -->
              <!-- {{ item }} -->
              <v-badge bordered color="#dddddd" :content="item.qty" overlap
                ><div class="image-item">
                  <img v-if="!item.image" style="width: 50px" :src="'@/assets/images/no-image.png'" alt="" />
                  <img style="width: 100%" v-else :src="imageHelpers.url(item.image)" />
                </div>
              </v-badge>
            </td>
            <td class="py-3">
              <router-link
                v-if="item.sku !== 'tip'"
                :to="{ name: 'website.products.update', params: { id: item.product_id } }"
              >
                {{ item._title }}
              </router-link>
              <span v-else>
                {{ item._title }}
              </span>
              <div>{{ item._variant_variantTitle }}</div>
              <div v-for="(personalize, index) in item.personalizeText" :key="index">
                {{ personalize.name ? personalize.name : 'Name' }}: {{ personalize.text }}
              </div>
              <div>SKU:{{ item._productSku ? item._productSku : '' }}</div>
            </td>
            <td style="width: 100px">{{ currency.priceFormat(item.price) }} x {{ item.qty }}</td>
            <td>{{ currency.priceFormat(parseFloat(item.price * item.qty).toFixed(2)) }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-divider class="my-5"></v-divider>
      <div class="d-flex align-center mb-6">
        <v-btn @click="cancelFulfill(fulfill._id)" elevation="" color="" class="mr-2">Cancel fulfillment</v-btn>
        <v-spacer />
        <v-btn
          v-once
          elevation=""
          @click="
            openAddTracking = true;
            fulFillmentId = fulfill._id;
            formData.trackingCompany = fulfill.trackingCompany;
            formData.trackingNumber = fulfill.trackingNumber;
          "
        >
          {{ getBtnTrackingLabel }}
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="dialog" persistent max-width="25vw">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mx-0" fab text small color="#000" @click="closePreview()">
            <v-icon dark>
              mdi-close-circle-outline
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <img
            style="width: 100%"
            v-if="productPreview && productPreview.value"
            :src="imageHelpers.url(productPreview.value)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <modal model="openAddTracking" :title="getBtnTrackingLabel" :callback="onSubmitAddTracking" v-if="renderModal">
      <template slot="content">
        <div>
          <label for="">Tracking numbers</label>
          <v-text-field
            @blur="$v.formData.$touch()"
            v-model="$v.formData.trackingNumber.$model"
            :error-messages="
              $v.formData.trackingNumber.$dirty && !$v.formData.trackingNumber.required
                ? 'Tracking number is required'
                : ''
            "
          ></v-text-field>
        </div>
        <div>
          <label for="">Carrier</label>
          <select name="Carrier" id="Carrier" class="form-control" v-model="formData.trackingCompany">
            <option v-for="(carrier, index) in carriers" :key="index" :value="carrier.value">{{ carrier.name }}</option>
          </select>
        </div>
        <div>
          <v-checkbox v-model="sendEmail" label="Send notification email to customer"></v-checkbox>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { orderApi } from '@/apis/order';
import { imageFake } from '@/const/image';
import imageHelpers from '@/helpers/image';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import currency from '@/helpers/currency';

export default {
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  validations: {
    formData: {
      trackingNumber: {
        required,
      },
    },
  },
  data() {
    return {
      openAddTracking: false,
      fulFillmentList: [],
      itemsFulfilled: [],
      itemsFulfilledList: [],
      itemsUnfulfilled: [],
      fulFillment: null,
      fulFillmentId: null,
      isLoading: false,
      isLoadingAction: false,
      imageFake,
      imageHelpers,
      sendEmail: true,
      renderModal: false,
      formData: {
        trackingNumber: null,
        trackingCompany: null,
      },
      dialog: false,
      productPreview: null,
      carriers: [
        {
          name: 'DHL',
          value: 'DHL_GLOBAL_MAIL',
        },
        {
          name: '4PX',
          value: 'FOUR_PX_EXPRESS',
        },
        {
          name: 'AU Post',
          value: 'AUSTRALIA_POST',
        },
        {
          name: 'USPS',
          value: 'USPS',
        },
        {
          name: 'Fedex',
          value: 'FEDEX',
        },
        {
          name: 'EMS',
          value: 'CHINA_POST',
        },
        {
          name: 'CA Post',
          value: 'CANADA_POST',
        },
        {
          name: 'UPS',
          value: 'UPS',
        },
        {
          name: 'OnTrac',
          value: 'US_ONTRAC',
        },
        {
          name: 'DHL eCommerce US',
          value: 'DHL_GLOBAL_MAIL',
        },
        {
          name: 'DHL Global eCommerce',
          value: 'DHL_GLOBAL_ECOMMERCE',
        },
        {
          name: 'Yanwen',
          value: 'YANWEN_CN',
        },
        {
          name: 'China Post',
          value: 'CHINA_POST',
        },
        {
          name: 'Malaysia Post EMS',
          value: 'MALAYSIA_POST',
        },
        {
          name: 'Epacket',
          value: 'EMS_CN',
        },
        {
          name: 'EMS China',
          value: 'EMS_CN',
        },
        {
          name: 'HONGKONG POST',
          value: 'HK_POST',
        },
        {
          name: 'SF EXPRESS',
          value: 'SF_EXPRESS',
        },
        {
          name: 'Singapore Post',
          value: 'SINGPOST',
        },
        {
          name: 'Russia Post',
          value: 'RUSSIAN_POST',
        },
        {
          name: 'Poste Italiane',
          value: 'POSTE_ITALIANE',
        },
        {
          name: 'LA Poste',
          value: 'LAPOSTE',
        },
        {
          name: 'Other',
          value: 'OTHER',
        },
      ],
      currency,
    };
  },
  created() {
    this.loadDataFulfillment();
  },
  methods: {
    convertDataFulfillment(itemFulfill) {
      let obj = { ...itemFulfill, sum: 0, itemFulfill: [] };
      for (let i = 0; i < itemFulfill.lineItems.length; i++) {
        obj.sum = obj.sum + itemFulfill.lineItems[i].quantity;
        let product = this.order.itemsInfo.find(item => {
          return item.item_id === itemFulfill.lineItems[i].id;
        });
        if (product) {
          product.qty = itemFulfill.lineItems[i].quantity;
          obj.itemFulfill.push(product);
        }
      }
      return obj;
    },
    async onSubmitAddTracking() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$invalid) {
        try {
          await orderApi.updateFulfillment(this.order.id, this.fulFillmentId, this.formData);
          this.openAddTracking = false;
          this.fulFillmentId = null;
          this.$emit('reload');
        } catch (error) {
          console.log(error);
        }
      }
    },
    async loadDataFulfillment() {
      let vm = this;
      this.isLoading = true;
      try {
        let fulfillmentResponse = await orderApi.getFulfillmentByIdOrder(this.order.id);
        this.renderModal = true;
        this.formData.trackingNumber = fulfillmentResponse.data.fulfillments[0]?.trackingNumber;
        this.formData.trackingCompany = fulfillmentResponse.data.fulfillments[0]?.trackingCompany;
        this.fulFillmentList = fulfillmentResponse.data.fulfillments.map(item => {
          // console.log(this.convertDataFulfillment(item));
          return this.convertDataFulfillment(item);
        });

        this.fulFillmentList.forEach(element => {
          element.lineItems.forEach(el => {
            if (this.itemsFulfilled.find(i => i.id === el.id)) {
              let index = this.itemsFulfilled.findIndex(i => i.id === el.id);
              if (index != -1) {
                vm.$set(
                  this.itemsFulfilled[0],
                  'quantity',
                  this.itemsFulfilled.find(i => i.id === el.id).quantity + el.quantity,
                );
              }
            } else {
              this.itemsFulfilled.push(el);
            }
          });
        });
        this.order.items.forEach(item => {
          let findItem = this.itemsFulfilled.find(i => i.id === item.item_id);
          let objFulfilled = {
            item_id: item.item_id,
            product_id: item.product_id,
            image: item.image,
            title: item.title,
            attributes: item.attributes,
            _title: item._title,
            _variant_variantTitle: item._variant_variantTitle,
            price: item.price,
            _sku: item._sku,
            _vendor: item._vendor,
            sku: item.sku,
            personalizeText: item.personalizeText,
          };
          let obj = {};
          obj.item_id = item.item_id;
          obj.product_id = item.product_id;
          obj.image = item.image;
          obj.title = item.title;
          obj._title = item._title;
          obj.attributes = item.attributes;
          obj._variant_variantTitle = item._variant_variantTitle;
          obj.price = item.price;
          obj._sku = item._sku;
          obj._productSku = item._productSku;
          obj.sku = item.sku;
          obj._vendor = item._vendor;
          obj.personalizeText = item.personalizeText;
          if (findItem) {
            if (parseInt(findItem.quantity) < parseInt(item.qty)) {
              obj.qty = parseInt(item.qty) - parseInt(findItem.quantity);
              this.itemsUnfulfilled.push(obj);
            }
            objFulfilled.qty = findItem.quantity;
            this.itemsFulfilledList.push(objFulfilled);
          } else {
            obj.qty = parseInt(item.qty);
            this.itemsUnfulfilled.push(obj);
          }
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async cancelFulfill(idFulfill) {
      this.isLoadingAction = true;
      try {
        await orderApi.cancelFulfillment(this.order.id, idFulfill);
        this.$emit('reload');
        this.isLoadingAction = false;
      } catch (error) {
        this.isLoadingAction = false;
      }
    },
    isPersonalizeProduct(item) {
      return Array.isArray(item?.personalizeText) && item?.personalizeText?.length > 0;
    },
    getPersonalizeTxt(item) {
      return item.personalizeText
        .map(item => {
          return item.text;
        })
        .join(',');
    },
    personalizeText(item) {
      let data = [];
      let findItem = this.data.paidInfo.items.find(i => i.item_id == item.item_id);
      data = findItem ? findItem.personalizeText : [];
      return data;
    },
    openPreview(item) {
      this.productPreview = item.attributes.find(i => i.key === '_personalbridge_preview');
      this.dialog = true;
      this.$forceUpdate();
      console.log('test');
    },
    closePreview() {
      this.dialog = false;
      this.productPreview = null;
      this.$forceUpdate();
    },
  },
  computed: {
    getBtnTrackingLabel() {
      console.log(`this.formData.trackingNumber ${this.formData.trackingNumber}`);
      return this.formData.trackingNumber ? `Edit tracking` : `Add tracking`;
    },
  },
};
</script>
